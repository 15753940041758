import React from "react";
import { motion } from "framer-motion/dist/framer-motion";
import "./collaborate.style.css";
import Pin from "../../assets/images/pin.svg";
import Button from "../../components/button/button";
import Footer from "../../components/footer/footer";
import { useInView, useAnimation } from "framer-motion/dist/framer-motion";
import { useRef, useEffect } from "react";

const Collaborate = () => {
  const fileInput = useRef();
  const selectFile = () => {
    fileInput.current.click();
  };
  const interestValue = [];
  let budgetValue = "<$5K";
  const ref1 = useRef(null);
  const ref2 = useRef(null);
  const ref3 = useRef(null);
  const ref4 = useRef(null);
  const ref5 = useRef(null);
  const ref6 = useRef(null);
  const ref7 = useRef(null);
  const ref8 = useRef(null);

  const Controls1 = useAnimation();
  const Controls2 = useAnimation();
  const Controls3 = useAnimation();
  const Controls4 = useAnimation();
  const Controls5 = useAnimation();
  const Controls6 = useAnimation();
  const Controls7 = useAnimation();
  const Controls8 = useAnimation();

  const isInView1 = useInView(ref1);
  const isInView2 = useInView(ref2);
  const isInView3 = useInView(ref3);
  const isInView4 = useInView(ref4);
  const isInView5 = useInView(ref5);
  const isInView6 = useInView(ref6);
  const isInView7 = useInView(ref7);
  const isInView8 = useInView(ref8);

  useEffect(() => {
    if (isInView1) {
      Controls1.start("visible");
    } else {
      Controls1.start("hidden");
    }
  }, [isInView1]);

  useEffect(() => {
    if (isInView2) {
      Controls2.start("visible");
    } else {
      Controls2.start("hidden");
    }
  }, [isInView2]);

  useEffect(() => {
    if (isInView3) {
      Controls3.start("visible");
    } else {
      Controls3.start("hidden");
    }
  }, [isInView3]);

  useEffect(() => {
    if (isInView4) {
      Controls4.start("visible");
    } else {
      Controls4.start("hidden");
    }
  }, [isInView4]);
  useEffect(() => {
    if (isInView5) {
      Controls5.start("visible");
    } else {
      Controls5.start("hidden");
    }
  }, [isInView5]);
  useEffect(() => {
    if (isInView6) {
      Controls6.start("visible");
    } else {
      Controls6.start("hidden");
    }
  }, [isInView6]);
  useEffect(() => {
    if (isInView7) {
      Controls7.start("visible");
    } else {
      Controls7.start("hidden");
    }
  }, [isInView7]);
  useEffect(() => {
    if (isInView8) {
      Controls8.start("visible");
    } else {
      Controls8.start("hidden");
    }
  }, [isInView8]);

  const variants1 = {
    visible: { opacity: 1, x: 0 },
    hidden: { opacity: 0, x: "-10vh" },
  };

  const variants2 = {
    visible: { opacity: 1, y: 0 },
    hidden: { opacity: 0, y: "10vh" },
  };

  const variants3 = {
    visible: { opacity: 1, y: -0 },
    hidden: { opacity: 0, y: "10vh" },
  };

  const updateInterest = (e) => {
    if (e.target.className.includes("coll_selector_selected")) {
      e.target.className = "coll_selector";
      interestValue.splice(interestValue.indexOf(e.target.innerText), 1);
    } else {
      e.target.className = "coll_selector coll_selector_selected";
      console.log(e);
      interestValue.push(e.target.innerText);
    }
    console.log(interestValue);
  };

  const updateBudget = (e) => {
    const budgetSelectors = document.querySelectorAll(".budget_select");
    budgetSelectors.forEach((b) => {
      b.className = "budget_select";
    });
    e.target.className = e.target.className.includes("budget_select_selected")
      ? "budget_select"
      : "budget_select budget_select_selected";
    budgetValue = e.target.innerText;
    console.log(budgetValue);
  };

  const variants4 = {
    visible: { opacity: 1, y: -0 },
    hidden: { opacity: 0, y: "10vh" },
  };
  const variants5 = {
    visible: { opacity: 1, y: -0 },
    hidden: { opacity: 0, y: "10vh" },
  };
  const variants6 = {
    visible: { opacity: 1, y: -0 },
    hidden: { opacity: 0, y: "10vh" },
  };
  const variants7 = {
    visible: { opacity: 1, y: -0 },
    hidden: { opacity: 0, y: "10vh" },
  };
  const variants8 = {
    visible: { opacity: 1, y: -0 },
    hidden: { opacity: 0, y: "10vh" },
  };

  return (
    <div className="container">
      <motion.div
        className="collaborate"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
      >
        <div className="row">
          <motion.div
            className="col-lg-6 col-sm-12"
            ref={ref1}
            variants={variants1}
            initial="hidden"
            animate={Controls1}
            whileInView="visible"
            viewport={{ once: true }}
            transition={{ duration: 1 }}
          >
            <h1 className="contact_main">
              Hey!<span className="wave">👋</span> Tell us all the things
              <div className="contact_hr"></div>
            </h1>
            <div>
              <p className="con_location">Location</p>
              <p className="con_address">
                307-308, Tulsi Arcade, Opp. Friday Cinema, Sudama Chowk, Mota
                varachhchha, Surat
              </p>
            </div>
          </motion.div>

          <div className="col-lg-6 col-sm-12">
            <motion.div
              className="con_wrapper"
              ref={ref2}
              variants={variants2}
              initial="hidden"
              animate={Controls2}
              whileInView="visible"
              viewport={{ once: true }}
              transition={{ duration: 1 }}
            >
              <p className="contact_heading">I'm interested in...</p>
              <div className="coll_tag">
                <span
                  className="coll_selector"
                  onClick={(e) => updateInterest(e)}
                >
                  Web development
                </span>
                <span
                  className="coll_selector"
                  onClick={(e) => updateInterest(e)}
                >
                  App Development
                </span>
                <span
                  className="coll_selector"
                  onClick={(e) => updateInterest(e)}
                >
                  UI UX design
                </span>
                <span
                  className="coll_selector"
                  onClick={(e) => updateInterest(e)}
                >
                  Site from scratch
                </span>
                <span
                  className="coll_selector"
                  onClick={(e) => updateInterest(e)}
                >
                  Maintenance
                </span>
              </div>
            </motion.div>

            <form className="form_contact">
              <div className="form">
                <motion.div
                  ref={ref3}
                  variants={variants3}
                  initial="hidden"
                  animate={Controls3}
                  transition={{
                    ease: "easeInOut",
                    duration: 1,
                    delay: 0.3,
                  }}
                >
                  <input
                    type="text"
                    placeholder="Your name"
                    className="form_wrapper"
                  />
                </motion.div>
                <motion.div
                  ref={ref4}
                  variants={variants4}
                  initial="hidden"
                  animate={Controls4}
                  transition={{
                    ease: "easeInOut",
                    duration: 1,
                    delay: 0.4,
                  }}
                >
                  <input
                    type="email"
                    placeholder="Your email"
                    className="form_wrapper"
                  />
                </motion.div>
                <motion.div
                  ref={ref5}
                  variants={variants5}
                  initial="hidden"
                  animate={Controls5}
                  transition={{
                    ease: "easeInOut",
                    duration: 1,
                    delay: 0.5,
                  }}
                >
                  <p className="budget">Your budget</p>
                  <div className="budget_tag">
                    <span
                      className="budget_select"
                      onClick={(e) => updateBudget(e)}
                    >
                      &lt;$5k
                    </span>
                    <span
                      className="budget_select"
                      onClick={(e) => updateBudget(e)}
                    >
                      $5k to $10k
                    </span>
                    <span
                      className="budget_select"
                      onClick={(e) => updateBudget(e)}
                    >
                      $10k to $20k
                    </span>
                    <span
                      className="budget_select"
                      onClick={(e) => updateBudget(e)}
                    >
                      &gt;$20k
                    </span>
                  </div>
                </motion.div>
                <motion.div
                  ref={ref6}
                  variants={variants6}
                  initial="hidden"
                  animate={Controls6}
                  transition={{
                    ease: "easeInOut",
                    duration: 1,
                    delay: 0.4,
                  }}
                >
                  <textarea
                    rows=""
                    cols="50"
                    placeholder="Tell us about your project"
                    className="form_textarea_wrapper"
                  />
                </motion.div>
              </div>
              <motion.div
                className="d-flex pin"
                ref={ref7}
                variants={variants7}
                initial="hidden"
                animate={Controls7}
                transition={{
                  ease: "easeInOut",
                  duration: 1,
                  delay: 0.7,
                }}
                onClick={selectFile}
              >
                <input
                  type="file"
                  style={{ display: "none" }}
                  ref={fileInput}
                />
                <img
                  src={Pin}
                  alt="pin"
                  style={{ height: "24px", width: "24px" }}
                />
                <p className="attach">Add attachment</p>
              </motion.div>
              <motion.div
                ref={ref8}
                variants={variants8}
                initial="hidden"
                animate={Controls8}
                transition={{
                  ease: "easeInOut",
                  duration: 1,
                  delay: 0.7,
                }}
              >
                <Button
                  text="Send request"
                  className="main_btn"
                  icon={
                    <svg
                      className="icon_svg"
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M6 14L14 6M14 6H6M14 6V14"
                        stroke=""
                        strokeLinecap="square"
                      />
                    </svg>
                  }
                />
              </motion.div>
            </form>
          </div>
        </div>
      </motion.div>
      <Footer />
    </div>
  );
};

export default Collaborate;
