import React from "react";
import brand_1 from "../../assets/images/culture_img/brand_1.png";
import brand_2 from "../../assets/images/culture_img/brand_2.png";
import brand_3 from "../../assets/images/culture_img/brand_3.png";
import brand_4 from "../../assets/images/culture_img/brand_4.png";
import brand_5 from "../../assets/images/culture_img/brand_5.png";
import "./culture.style.css";

const cultureSlider = () => {
  const imageItem = [
    {
      id: 1,
      img: brand_1,
      name:"WT le guichet mobile"
    },
    {
      id: 2,
      img: brand_2,
      name:"Main qui"
    },
    {
      id: 3,
      img: brand_3,
      name:"Tendex"
    },
    {
      id: 4,
      img: brand_4,
      name:"Studio print"
    },
    {
      id: 5,
      img: brand_5,
      name:"Aivara"
    },
  ];
  return (
    <article className="artical">
      <div className="marquee">
        <div className="marquee__group">
          {imageItem.map((img) => {
            return (
              <span className="brand" key={img.id}>
                <img src={img.img} alt={img.name} className="img-fluid" />
              </span>
            );
          })}
        </div>

        <div aria-hidden="true" className="marquee__group">
          {imageItem.map((img) => {
            return (
              <span className="brand" key={img.id}>
                <img src={img.img} alt="" className="img-fluid" />
              </span>
            );
          })}
        </div>
      </div>

      <div className="marquee marquee--reverse">
        <div className="marquee__group">
          {imageItem.map((img) => {
            return (
              <span className="brand" key={img.id}>
                <img src={img.img} alt="" className="img-fluid" />
              </span>
            );
          })}
        </div>

        <div aria-hidden="true" className="marquee__group">
          {imageItem.map((img) => {
            return (
              <span className="brand" key={img.id}>
                <img src={img.img} alt="" className="img-fluid" />
              </span>
            );
          })}
        </div>
      </div>
    </article>
  );
};

export default cultureSlider;
