import React from "react";
import "./home.style.css";
import BigArrow from "../../assets/images/big_arrow.svg";
import project_1 from "../../assets/images/project_img/bg_4.png";
import project_2 from "../../assets/images/project_img/bg_1.png";
import project_3 from "../../assets/images/project_img/bg_2.png";
import project_4 from "../../assets/images/project_img/bg_3.png";

import project_icon_1 from "../../assets/images/project_icon/pro_icon1.svg";
import project_icon_2 from "../../assets/images/project_icon/pro_icon2.svg";
import project_icon_3 from "../../assets/images/project_icon/pro_icon3.svg";
import project_icon_4 from "../../assets/images/project_icon/pro_icon4.svg";
import { useRef, useEffect } from "react";
import {
  motion,
  useInView,
  useAnimation,
} from "framer-motion/dist/framer-motion";

const projectsItem = [
  {
    id: 1,
    image: project_1,
    logo: project_icon_1,
    heading: "Onquity",
    name: "Onquity",
  },
  {
    id: 2,
    image: project_2,
    logo: project_icon_2,
    heading: "UI/UX Design",
    name: "Kashyap Impex",
  },
  {
    id: 3,
    image: project_3,
    logo: project_icon_3,
    heading: "UI/UX Design",
    name: "Tendex",
  },
  {
    id: 4,
    image: project_4,
    logo: project_icon_4,
    heading: "Hubber  ",
    name: "Hubber ",
  },
];

const ProjectCardItem = ({ project }) => {

  const ref1 = useRef(null);
  const Controls1 = useAnimation();
  const isInView1 = useInView(ref1);

  useEffect(() => {
    if (isInView1) {
      Controls1.start("visible");
    } else {
      Controls1.start("hidden");
    }
  }, [isInView1]);

  const variants1 = {
    visible: { opacity: 1, y: 0 },
    hidden: { opacity: 0, y: "10vh" },
  };

  return (
    <motion.div
      ref={ref1}
      variants={variants1}
      initial="hidden"
      animate={Controls1}
      whileInView="visible"
      viewport={{ once: true }}
      transition={{ duration: 1, delay: 0.3 }}
      className="col-lg-6 col-md-6 col-sm-12 mb-4"
    >
      <div className="card project_card">
        <div className="card_background">
          <img src={project.image} alt="project" className="card_image"></img>
        </div>
        <div className="card_icon">
          <img src={BigArrow} alt="BigArrow"></img>
        </div>
        <div className="card_content">
          <div className="card_detail">
            <div className="d-flex gap-2">
              <div className="project_logo">
                <img src={project.logo} alt="" className="project_logo_img" />
              </div>
              <div className="project_heading">{project.heading}</div>
            </div>
            <div className="project_name">
              <p>{project.name}</p>
            </div>
          </div>
        </div>
      </div>
    </motion.div>
  );
};

const ProjectCard = () => {
  return (
    <div className="row">
      {projectsItem.map((project) => (
        <ProjectCardItem key={project.id} project={project} />
      ))}
    </div>
  );
};

export default ProjectCard;
