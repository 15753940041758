import React, {useEffect, useRef} from "react";
import "./button.style.css";
import { motion, useInView, useAnimation } from "framer-motion/dist/framer-motion";

const Button = (props) => {
  const {
    text,
    disabled,
    className,
    onClick,
    link,
    isPrimary,
    isSecondary,
    size,
    icon,
    ...rest
  } = props;

  // const ref = useRef(null);
  // const isInView = useInView(ref);

  // const mainControls = useAnimation();
  // useEffect(()=>{
  //   console.log("fff", isInView);
  //   if(isInView){
  //     //fire the animation
  //     mainControls.start("visible");
  //   } else {
  //     mainControls.start("hidden");
  //   }
  // }, [isInView])

  // const variants = {
  //   visible: { opacity: 1 , y: 0},
  //   hidden: { opacity: 0 , y: 75},
  // }
  return (
    // <motion.div ref={ref} variants={variants} initial="hidden" animate={mainControls} transition={{duration:0.5, delay: 0.25}}>
    <button onClick={onClick} disabled={disabled} className={className}>
      {text}
     {icon && <div className="btn_arrow">{icon}</div>} 
    </button>
    // </motion.div>
  );
};

export default Button;
