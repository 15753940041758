import React, {useEffect, useState} from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import Home from "./home/home.index";
import Culture from "./culture/culture.index";
import Projects from "./projects/project.index";
import Collaborate from "./collaborate/collaborate.index";
import JoinUs from "./joinus/joinus.index";
import Loader from "../components/loader/loader"
import { AnimatePresence } from "framer-motion/dist/framer-motion";
import Navbar from "../components/navbar/navbar";


const AnimatedRoutes = () => {
 
  const location = useLocation();
  const [isLoading, setIsLoading] = useState(true); 

  useEffect(() => {
    const loadingDelay = setTimeout(() => {
      setIsLoading(false);
    }, 5000);
  
    return () => clearTimeout(loadingDelay);
  }, []);
  return (
    <AnimatePresence>
      {isLoading ? (
        // Render your loader component here
        <Loader locationData = {location.pathname.replace('/',"")}/>
      ) : (
        <>
         <Navbar />
        <Routes location={location} key={location.pathname}>
          <Route path="/" element={<Home />}></Route>
          <Route path="/culture" element={<Culture />}></Route>
          <Route path="/projects" element={<Projects />}></Route>
          <Route path="/join-us" element={<JoinUs />}></Route>
          <Route path="/collaborate" element={<Collaborate />}></Route>
        </Routes>
        </>
      )}
    </AnimatePresence>
  );
};

export default AnimatedRoutes;
