import React, { useState, useRef, useEffect } from "react";
import "./navbar.style.css";
import { NavLink } from "react-router-dom";
import {
  motion,
  useAnimation,
  useInView,
} from "framer-motion/dist/framer-motion";

const Navbar = ({ onNavLinkClick }) => {
  const [showNavbar, setShowNavbar] = useState(false);
  const handleShowNavbar = (e) => {
    e.preventDefault();
    setShowNavbar(!showNavbar);
  };

  const ref4 = useRef(null);
  const isInView4 = useInView(ref4);
  const Controls4 = useAnimation();

  // change navbar class on scroll
  const [width, setWidth] = useState(100);
  const [size, setSize] = useState(false);

  const widthChange = async () => {
    if (size == false) {
      if (window.innerWidth >= 1400 && window.scrollY >= 100) {
        setSize(true);
        for (let i = 0; i < 70; i++) {
          setWidth(i);
        }
        setSize(false);
      } else if (window.innerWidth >= 1200 && window.scrollY >= 100) {
        setSize(true);
        for (let i = 0; i < 73; i++) {
          setWidth(i);
        }
        setSize(false);
      } else if (window.innerWidth >= 1024 && window.scrollY >= 100) {
        setSize(true);
        for (let i = 0; i < 85; i++) {
          setWidth(i);
        }
        setSize(false);
      } else if (window.innerWidth > 992 && window.scrollY >= 100) {
        setSize(true);
        for (let i = 0; i < 60; i++) {
          setWidth(i);
        }
        setSize(false);
      } else if (window.innerWidth <= 992 && window.scrollY >= 100) {
        setSize(true);
        for (let i = 0; i < 90; i++) {
          setWidth(i);
        }
        setSize(false);
      }
      else {
        setSize(true);
        for (let i = 0; i < 100; i++) {
          setWidth(i);
        }
        setSize(false);
      }
    }
  };
  window.addEventListener("scroll", widthChange);

  const [navChange, setNavChange] = useState(false);
  const changeNavbarColor = () => {
    if (window.scrollY >= 100) {
      setNavChange(true);
    } else {
      setNavChange(false);
    }
  };
  window.addEventListener("scroll", changeNavbarColor);

  const [logo, setLogo] = useState(false);
  const changeLogoColor = () => {
    if (window.scrollY >= 100) {
      setLogo(true);
    } else {
      setLogo(false);
    }
  };
  window.addEventListener("scroll", changeLogoColor);

  const [collaborate, setCollaborate] = useState(false);
  const changeCollaborate = () => {
    if (window.scrollY >= 100) {
      setCollaborate(true);
    } else {
      setCollaborate(false);
    }
  };
  window.addEventListener("scroll", changeCollaborate);

  useEffect(() => {
    if (isInView4) {
      Controls4.start("visible");
    } else {
      Controls4.start("hidden");
    }
  }, [isInView4, widthChange]);

  const variants4 = {
    hidden: { opacity: 0, y: 10 },
    visible: { opacity: 1, y: 0 },
  };

  return (
    <>
      <motion.nav
        ref={ref4}
        initial="hidden"
        variants={variants4}
        animate={Controls4}
        transition={{ duration: 1, ease: "linear" }}
        className="navbar"
      >
        <div
          className={
            navChange ? "navbar-container navChange " : "navbar-container"
          }
          style={{ maxWidth: `${width}%` }}
        >
          <div className="logo">
            <a className="navbar_brand" href="/">
              <svg
                className={logo ? "logo_icon logoChange" : "logo_icon"}
                width="40"
                height="40"
                viewBox="0 0 40 40"
                fill=""
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M20 0H0V15.7675L24.8637 15.7675L24.8637 39.4045C33.5589 37.232 40 29.3682 40 20C40 8.9543 31.0457 0 20 0ZM20.336 39.9972L20.3213 23.5201L3.84141 40H20C20.1122 40 20.2242 39.9991 20.336 39.9972ZM0 37.421L17.1111 20.3099L0 20.2946V37.421Z"
                  fill=""
                />
              </svg>
            </a>
          </div>
          <div className="menu_icon">
            <a className="navbar_brand" onClick={handleShowNavbar}>
              {showNavbar ? (
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M17 7L7 17M7 7L17 17"
                    stroke="#F1F1F1"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              ) : (
                <svg
                  className={
                    logo ? "Hamburger_icon change_Hamburger" : "Hamburger_icon"
                  }
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill=""
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M3 12H15M3 6H21M3 18H21"
                    stroke=""
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              )}
            </a>
          </div>

          <div className={`menu_item ${showNavbar ? "active" : " "}`}>
            <ul>
              <li>
                <NavLink
                  className={logo ? "changeMenu" : ""}
                  to="/"
                  onClick={() => {
                    onNavLinkClick("/");
                  }}
                >
                  Home
                </NavLink>
              </li>
              <li>
                <NavLink
                  className={logo ? "changeMenu" : ""}
                  to="/culture"
                  onClick={() => {
                    onNavLinkClick("/culture");
                  }}
                >
                  Culture
                </NavLink>
              </li>
              <li>
                <NavLink
                  className={logo ? "changeMenu" : ""}
                  to="/projects"
                  onClick={() => {
                    onNavLinkClick("/projects");
                  }}
                >
                  Projects
                </NavLink>
              </li>
              <li>
                <NavLink
                  className={logo ? "changeMenu" : ""}
                  to="/join-us"
                  onClick={() => {
                    onNavLinkClick("/join-us");
                  }}
                >
                  Join us
                </NavLink>
              </li>
            </ul>
            <hr />
            <div className="lets">
              <a
                href="/collaborate"
                className={
                  collaborate
                    ? "navbar_button changeCollaborate"
                    : "navbar_button"
                }
                title="Let’s Collaborate"
              >
                {" "}
                Let’s collaborate
                <div className="btn_arrow">
                  <svg
                    className={logo ? "icon_svg arrowChange" : "icon_svg"}
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M6 14L14 6M14 6H6M14 6V14"
                      stroke=""
                      strokeLinecap="square"
                    />
                  </svg>
                </div>
              </a>
            </div>

            <div className="nav_email">
              <a href="mailto:hello@doubledotts.com">hello@doubledotts.com</a>
            </div>
          </div>
        </div>
      </motion.nav>
    </>
  );
};

export default Navbar;
