import React from "react";
import "./loader.style.css";
import loader from "../../assets/images/loader/circle.svg";
import BigArrow from "../../assets/images/big_arrow.svg";

const Loader = (props) => {
  const data = props.locationData == "" ? 'Home' : props.locationData;
  const splitData = data.split("-");
  const loaderText = [];
  splitData.map((key,index)=>{
    loaderText.push(index==0 ? key.charAt(0).toUpperCase() + key.slice(1) : key);
  });
  return (
    <>
      <div className="loader-container">
      <img className={"BigArrow"} src={BigArrow} alt="bigArrow"></img>
       <img className={"gif"} src={loader} alt="bigArrow"></img>
        
        <div className="loader">
         
          <h1 className="loader-text" >{loaderText.join(" ")}</h1>
        
        </div>
      </div>
    </>
  );
};

export default Loader;
