import React, { useEffect, useRef } from "react";
import "./footer.style.css";
import { Link } from "react-router-dom";
import { ReactComponent as InstaIcon } from "../../assets/images/social_icon/insta.svg";
import { ReactComponent as LinkedIcon } from "../../assets/images/social_icon/linkedin.svg";
import { ReactComponent as BehanceIcon } from "../../assets/images/social_icon/behance.svg";
import {
  motion,
  useInView,
  useAnimation,
} from "framer-motion/dist/framer-motion";

const socialMediaData = [
  {
    id: 1,
    name: "Instagram",
    icon: <InstaIcon className="icon_svg" />,
    url: "https://www.instagram.com/_doubledotts/",
  },
  {
    id: 2,
    name: "LinkedIn",
    icon: <LinkedIcon className="icon_svg" />,
    url: "https://www.linkedin.com/",
  },
  {
    id: 3,
    name: "Behance",
    icon: <BehanceIcon className="icon_svg" />,
    url: "https://www.behance.net/",
  },
];

const Footer = () => {
  const ref1 = useRef(null);
  const ref2 = useRef(null);

  const isInView1 = useInView(ref1);
  const isInView2 = useInView(ref2);

  const Controls1 = useAnimation();
  const Controls2 = useAnimation();

  useEffect(() => {
    if (isInView1) {
      Controls1.start("visible");
    } else {
      Controls1.start("hidden");
    }
  }, [isInView1]);

  useEffect(() => {
    if (isInView2) {
      Controls2.start("visible");
    } else {
      Controls2.start("hidden");
    }
  }, [isInView2]);

  const variants1 = {
    hidden: { opacity: 0, y: 50 },
    visible: { opacity: 1, y: 0 },
  };
  const variants2 = {
    visible: { opacity: 1, y: 0 },
    hidden: { opacity: 0, y: "10vh" },
  };

  return (
    <div>
      <div className="footer_wrapper">
        <motion.div
          ref={ref1}
          variants={variants1}
          initial="hidden"
          animate={Controls1}
          transition={{
            ease: "easeInOut",
            duration: 1,
            delay: 0.1,
          }}
        >
          <a href="/collaborate" className="talk_section">
            <div className="big_font">Let's Talk</div>
            <svg
              className="big_arrow"
              width="146"
              height="146"
              viewBox="0 0 146 146"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M5.88208 140.118L141.118 4.88184M141.118 4.88184H5.88208M141.118 4.88184V140.118"
                stroke=""
                strokeWidth="8"
                strokeLinecap="square"
              />
            </svg>
          </a>
        </motion.div>

        <div className="social_wrapper">
          <div className="row">
            {socialMediaData.map((socialMedia) => (
              <motion.div
                className="col-lg-4 col-md-4 col-sm-12"
                key={socialMedia.id}
              >
                <motion.a
                  href={socialMedia.url}
                  className="social_box"
                  ref={ref2}
                  variants={variants2}
                  initial="hidden"
                  animate={Controls2}
                  whileInView="visible"
                  viewport={{ once: true }}
                  transition={{ duration: 1, delay: 0 }}
                >
                  <div className="social_icon">
                    <div className="d-flex align-items-center">
                      <a key={socialMedia.id} href={socialMedia.url}>
                        {socialMedia.icon}
                      </a>
                    </div>
                  </div>
                  <div className="social_detail">
                    <p>{socialMedia.name}</p>
                    <svg
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M6 14L14 6M14 6H6M14 6V14"
                        stroke=""
                        strokeLinecap="square"
                      />
                    </svg>
                  </div>
                </motion.a>
              </motion.div>
            ))}
          </div>
        </div>

        <div className="footer_bottom">
          <p>
            © 2023 <Link to="/">DoubleDotts</Link> Labs. All rights reserved{" "}
          </p>
          <a href="mailto:hello@doubledotts.com">hello@doubledotts.com</a>
        </div>
      </div>
    </div>
  );
};

export default Footer;
