import React, { useEffect } from "react";
import { motion } from "framer-motion/dist/framer-motion";
import "./culture.style.css";
import VideoPlayer from "../../components/videoplayer/videoplayer";
import cul_1 from "../../assets/images/culture_img/cul_1.png";
import cul_2 from "../../assets/images/culture_img/cul_2.png";
import cul_3 from "../../assets/images/culture_img/cul_3.png";
import cul_box_img1 from "../../assets/images/culture_img/cul_box_img1.png";
import cul_box_img2 from "../../assets/images/culture_img/cul_box_img2.png";
import cul_box_img3 from "../../assets/images/culture_img/cul_box_img3.png";
import cul_box_img4 from "../../assets/images/culture_img/cul_box_img4.png";
import cul_box_img5 from "../../assets/images/culture_img/cul_box_img5.png";
import cul_box_img6 from "../../assets/images/culture_img/cul_box_img6.png";
import cul_box_img7 from "../../assets/images/culture_img/cul_box_img7.png";
import cul_box_img8 from "../../assets/images/culture_img/cul_box_img8.png";
import cul_box_img9 from "../../assets/images/culture_img/cul_box_img9.png";
import cul_box_img10 from "../../assets/images/culture_img/cul_box_img10.png";
import cul_box_img11 from "../../assets/images/culture_img/cul_box_img11.png";

import Footer from "../../components/footer/footer";
import CultureSlider from "./culture.slider";
import { useInView, useAnimation } from "framer-motion/dist/framer-motion";
import { useRef } from "react";
import Rope from "../../components/rope/rope";

const Culture = () => {
  const ref1 = useRef(null);
  const ref2 = useRef(null);
  const ref3 = useRef(null);
  const ref4 = useRef(null);
  const ref5 = useRef(null);
  const ref6 = useRef(null);
  const ref7 = useRef(null);
  const ref8 = useRef(null);
  const ref9 = useRef(null);
  const ref10 = useRef(null);
  const ref11 = useRef(null);
  const ref12 = useRef(null);
  const Controls1 = useAnimation();
  const Controls2 = useAnimation();
  const Controls3 = useAnimation();
  const Controls4 = useAnimation();
  const Controls5 = useAnimation();
  const Controls6 = useAnimation();
  const Controls7 = useAnimation();
  const Controls8 = useAnimation();
  const Controls9 = useAnimation();
  const Controls10 = useAnimation();
  const Controls11 = useAnimation();
  const Controls12 = useAnimation();
  const isInView1 = useInView(ref1);
  const isInView2 = useInView(ref2);
  const isInView3 = useInView(ref3);
  const isInView4 = useInView(ref4);
  const isInView5 = useInView(ref5);
  const isInView6 = useInView(ref6);
  const isInView7 = useInView(ref7);
  const isInView8 = useInView(ref8);
  const isInView9 = useInView(ref9);
  const isInView10 = useInView(ref10);
  const isInView11 = useInView(ref11);
  const isInView12 = useInView(ref12);

  useEffect(() => {
    if (isInView1) {
      Controls1.start("visible");
    } else {
      Controls1.start("hidden");
    }
  }, [isInView1]);

  useEffect(() => {
    if (isInView2) {
      Controls2.start("visible");
    } else {
      Controls2.start("hidden");
    }
  }, [isInView2]);

  useEffect(() => {
    if (isInView3) {
      Controls3.start("visible");
    } else {
      Controls3.start("hidden");
    }
  }, [isInView3]);

  useEffect(() => {
    if (isInView4) {
      Controls4.start("visible");
    } else {
      Controls4.start("hidden");
    }
  }, [isInView4]);

  useEffect(() => {
    if (isInView5) {
      Controls5.start("visible");
    } else {
      Controls5.start("hidden");
    }
  }, [isInView5]);

  useEffect(() => {
    if (isInView6) {
      Controls6.start("visible");
    } else {
      Controls6.start("hidden");
    }
  }, [isInView6]);

  useEffect(() => {
    if (isInView7) {
      Controls7.start("visible");
    } else {
      Controls7.start("hidden");
    }
  }, [isInView7]);

  useEffect(() => {
    if (isInView8) {
      Controls8.start("visible");
    } else {
      Controls8.start("hidden");
    }
  }, [isInView8]);

  useEffect(() => {
    if (isInView9) {
      Controls9.start("visible");
    } else {
      Controls9.start("hidden");
    }
  }, [isInView9]);
  useEffect(() => {
    if (isInView10) {
      Controls10.start("visible");
    } else {
      Controls10.start("hidden");
    }
  }, [isInView10]);

  useEffect(() => {
    if (isInView11) {
      Controls11.start("visible");
    } else {
      Controls11.start("hidden");
    }
  }, [isInView11]);
  useEffect(() => {
    if (isInView12) {
      Controls12.start("visible");
    } else {
      Controls12.start("hidden");
    }
  }, [isInView12]);

  const variantsHead = {
    hidden: { opacity: 0, y: 90 },
    visible: { opacity: 1, y: "0" },
  };
  const variants2 = {
    visible: { opacity: 1, x: 0 },
    hidden: { opacity: 0, x: "-10vh" },
  };

  const variants3 = {
    visible: { opacity: 1, x: 0 },
    hidden: { opacity: 0, x: "-50vh" },
  };

  const variants4 = {
    visible: { opacity: 1, y: 0 },
    hidden: { opacity: 0, y: "5vh" },
  };

  const variants5 = {
    visible: { opacity: 1, y: 0 },
    hidden: { opacity: 0, y: "10vh" },
  };

  const variants6 = {
    visible: { opacity: 1, y: 0 },
    hidden: { opacity: 0, y: "10vh" },
  };

  const variants7 = {
    visible: { opacity: 1, x: 0 },
    hidden: { opacity: 0, x: "-10vh" },
  };

  const variants8 = {
    visible: { opacity: 1, x: 0 },
    hidden: { opacity: 0, x: "-10vh" },
  };
  const variants9 = {
    visible: { opacity: 1, x: 0 },
    hidden: { opacity: 0, x: "-10vh" },
  };
  const variants10 = {
    visible: { opacity: 1, x: 0 },
    hidden: { opacity: 0, x: "-10vh" },
  };
  const variants11 = {
    visible: { opacity: 1, x: 0 },
    hidden: { opacity: 0, x: "-10vh" },
  };
  const variants12 = {
    visible: { opacity: 1, x: 0 },
    hidden: { opacity: 0, x: "-10vh" },
  };
  return (
    <>
      <div className="culturee_wrraper">
        <div className="container">
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
          >
            <motion.div
              ref={ref1}
              variants={variantsHead}
              initial="hidden"
              animate={Controls1}
              transition={{
                ease: "easeInOut",
                duration: 1,
                delay: 0.6,
              }}
            >
              <p className="culture_heading">DOUBLEDOTTS</p>
              <p className="culture_sub">
                Convergence of <strong>creativity</strong> and{" "}
                <strong>technology.</strong>
              </p>
            </motion.div>
          </motion.div>
        </div>

        <VideoPlayer />
        <div className="container">
          <motion.h1
            className="culture_wrapper"
            ref={ref2}
            variants={variants2}
            initial="hidden"
            animate={Controls2}
            whileInView="visible"
            viewport={{ once: true }}
            transition={{ duration: 1 }}
          >
            We strive to imbue digital experiences with a human touch.
          </motion.h1>

          <motion.p
            className="culture_wrapper3"
            ref={ref5}
            variants={variants5}
            initial="hidden"
            animate={Controls5}
            whileInView="visible"
            viewport={{ once: true }}
            transition={{ duration: 1 }}
          >
            Lorem Ipsum is simply dummy text of the printing and typesetting
            industry. Lorem Ipsum has been the industry's standard dummy text
            ever since the 1500s
          </motion.p>

          <Rope />
        </div>

        <div className="cul_img_box">
          <div className="images_wrapper">
            <motion.div
              className="image"
              ref={ref6}
              variants={variants6}
              initial="hidden"
              animate={Controls6}
              transition={{
                ease: "easeInOut",
                duration: 1,
                delay: 0.4,
              }}
            >
              <img src={cul_1} alt="img" className="img-fluid image_1 " />
            </motion.div>
            <motion.div
              className="image"
              ref={ref6}
              variants={variants6}
              initial="hidden"
              animate={Controls6}
              transition={{
                ease: "easeInOut",
                duration: 1,
                delay: 0.6,
              }}
            >
              <img src={cul_2} alt="img" className="img-fluid " />
            </motion.div>
            <motion.div
              className="image"
              ref={ref6}
              variants={variants6}
              initial="hidden"
              animate={Controls6}
              transition={{
                ease: "easeInOut",
                duration: 1,
                delay: 0.8,
              }}
            >
              <img src={cul_3} alt="img" className="img-fluid image_3" />
            </motion.div>
          </div>
        </div>

        <div className="container">
          <div className="testimonial_wrapperr">
            <motion.div
              className="sub_heading"
              ref={ref9}
              variants={variants9}
              initial="hidden"
              animate={Controls9}
              whileInView="visible"
              viewport={{ once: true }}
              transition={{ duration: 1, delay: 0.4 }}
            >
              Values
            </motion.div>
            <motion.h1
              className="culture_h1"
              ref={ref10}
              variants={variants10}
              initial="hidden"
              animate={Controls10}
              whileInView="visible"
              viewport={{ once: true }}
              transition={{ duration: 1, delay: 0.5 }}
            >
              Core Value
            </motion.h1>
          </div>

          <motion.div
            className="core_value"
            ref={ref4}
            variants={variants4}
            initial="hidden"
            animate={Controls4}
            whileInView="visible"
            viewport={{ once: true }}
            transition={{ duration: 1, delay: 0.6 }}
          >
            <div className="row">
              <div className="col-lg-4 col-sm-12">
                <h3>Trust</h3>
                <p className="core_font">
                  {" "}
                  We understand that trust is a crucial component of any
                  successful business relationship. That's why we go above and
                  beyond to earn and maintain the trust of our clients. We do
                  this by providing exceptional service, transparent
                  communication, and a commitment to the highest standards of
                  quality and professionalism.
                </p>
              </div>

              <div className="col-lg-4 col-sm-12">
                <h3>Quality Work</h3>
                <p className="core_font">
                  {" "}
                  We are committed to delivering top-quality work to our
                  clients. We believe that attention to detail, a focus on
                  excellence, and a dedication to continuous improvement are the
                  keys to success. Our team is comprised of highly skilled
                  professionals who are experts in their respective fields.
                </p>
              </div>

              <div className="col-lg-4 col-sm-12">
                <h3>Clear Communiaction</h3>
                <p className="core_font">
                  {" "}
                  We believe that clear and consistent communication is
                  essential for the success of any project. We understand that
                  effective communication helps to build trust and ensure that
                  everyone is on the same page. That's why we prioritize clear,
                  timely, and accurate communication throughout the entire
                  process.
                </p>
              </div>
            </div>
          </motion.div>
        </div>

        <div className="testimonial_wrapper">
          <div className="container">
            <motion.div
              className="sub_heading"
              ref={ref11}
              variants={variants11}
              initial="hidden"
              animate={Controls11}
              whileInView="visible"
              viewport={{ once: true }}
              transition={{ duration: 1, delay: 0.4 }}
            >
              Our clients
            </motion.div>
            <motion.h1
              ref={ref7}
              variants={variants7}
              initial="hidden"
              animate={Controls7}
              whileInView="visible"
              viewport={{ once: true }}
              transition={{ duration: 1, delay: 0.5 }}
              className="cul_wrapper4"
            >
              From startups to well-established brands
            </motion.h1>
          </div>
          <CultureSlider />
        </div>

        {/* <div className="testimonial_wrapper">
          <div className="cul_bg">
            <div className="container">
              <motion.div
                className="sub_heading"
                ref={ref12}
                variants={variants12}
                initial="hidden"
                animate={Controls12}
                whileInView="visible"
                viewport={{ once: true }}
                transition={{ duration: 1, delay: 0.4 }}
              >
                Our team
              </motion.div>
              <motion.h1
                className="cul_wrap"
                ref={ref8}
                variants={variants8}
                initial="hidden"
                animate={Controls8}
                whileInView="visible"
                viewport={{ once: true }}
                transition={{ duration: 1, delay: 0.5 }}
              >
                {" "}
                Meet our team of developers, designers and world-class problem
                solver
              </motion.h1>
            </div>


            <div className="container employee_div">
              <div className="row cul_main_box ">
                <div className="col-6 col-lg-4 col-sm-6">
                  <div className="cul_box">
                    <div>CEO</div>
                    <img src={cul_box_img1} alt="" />
                    <span className="line1"></span>
                  </div>
                  <span className="cul_box_name">Aarzu Dholiya</span>
                </div>
                <div className="col-6 col-lg-4 col-sm-6">
                  <div className="cul_box">
                    <div>Co - Founder</div>
                    <img src={cul_box_img2} alt="" />
                    <span className="line1"></span>
                  </div>
                  <span className="cul_box_name">Deep Dholiya</span>
                </div>
                <div className="col-6 col-lg-4 col-sm-6">
                  <div className="cul_box">
                    <div>Project Delivery Manager</div>
                    <img src={cul_box_img3} alt="" />
                    <span className="line1"></span>
                  </div>
                  <span className="cul_box_name">Harshil Maniya</span>
                </div>
                <div className="col-6 col-lg-4 col-sm-6">
                  <div className="cul_box">
                    <div>React JS Developer</div>
                    <img src={cul_box_img4} alt="" />
                    <span className="line2"></span>
                  </div>
                  <span className="cul_box_name">Parita Talaviya</span>
                </div>
                <div className="col-6 col-lg-4 col-sm-6">
                  <div className="cul_box">
                    <div>Jr. Full Stack Developer</div>
                    <img src={cul_box_img5} alt="" />
                    <span className="line2"></span>
                  </div>
                  <span className="cul_box_name">Drashti Savani</span>
                </div>
                <div className="col-6 col-lg-4 col-sm-6">
                  <div className="cul_box">
                    <div>Node JS Developer</div>
                    <img src={cul_box_img6} alt="" />
                    <span className="line2"></span>
                  </div>
                  <span className="cul_box_name">Khyati Kotadiya</span>
                </div>
                <div className="col-6 col-lg-4 col-sm-6">
                  <div className="cul_box">
                    <div>Flutter Developer</div>
                    <img src={cul_box_img7} alt="" />
                    <span className="line2"></span>
                  </div>
                  <span className="cul_box_name">Ashish Kankad</span>
                </div>
                <div className="col-6 col-lg-4 col-sm-6">
                  <div className="cul_box">
                    <div>Jr. Node JS Developer</div>
                    <img src={cul_box_img8} alt="" />
                    <span className="line2"></span>
                  </div>
                  <span className="cul_box_name">Dhaval Maniya</span>
                </div>
                <div className="col-6 col-lg-4 col-sm-6">
                  <div className="cul_box">
                    <div>Jr. React JS Developer</div>
                    <img src={cul_box_img9} alt="" />
                    <span className="line2"></span>
                  </div>
                  <span className="cul_box_name">Krisha Maniya</span>
                </div>
                <div className="col-6 col-lg-4 col-sm-6">
                  <div className="cul_box">
                    <div>HR Manager</div>
                    <img src={cul_box_img10} alt="" />
                    <span className="line2"></span>
                  </div>
                  <span className="cul_box_name">Komal Somaiya</span>
                </div>
                <div className="col-6 col-lg-4 col-sm-6">
                  <div className="cul_box">
                    <div>BDE</div>
                    <img src={cul_box_img11} alt="" />
                    <span className="line2"></span>
                  </div>
                  <span className="cul_box_name">Avani Senta</span>
                </div>
              </div>
            </div>
          </div>
        </div> */}
      </div>

      <div className="container">
        <Footer />
      </div>
    </>
  );
};

export default Culture;
