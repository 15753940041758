import React, { useState } from "react";
import {
  BrowserRouter as Router,
  useNavigate,
  useLocation,
} from "react-router-dom";
import Navbar from "./components/navbar/navbar";

import "./App.css";
import AnimatedRoutes from "./pages/animatedroutes";
import Rope from "./components/rope/rope";

function App() {
  const pathName = window.location.pathname;
  let bgColor = "#FFFFFF";
  let className = "";
  const styleTag = document.createElement("style");
  let textNode = `::-webkit-scrollbar {
    width: 10px;
  }
  
  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey; 
    border-radius: 10px;
  }
   
  ::-webkit-scrollbar-thumb {
    background: #F1F1F1; 
    border-radius: 10px;
  }`;

  const textNodeWhite = `::-webkit-scrollbar {
    width: 10px;
    background: {{COLOR}};
  }
  
  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey; 
    border-radius: 10px;
  }
   
  ::-webkit-scrollbar-thumb {
    background: #060606; 
    border-radius: 10px;
  }`;
  switch (pathName) {
    case "/":
      bgColor = "#060606";
      break;
    case "/culture":
      bgColor = "#EADED7";
      className = "black";
      textNode = textNodeWhite.replace("{{COLOR}}","#EADED7");
      break;
    case "/projects":
      bgColor = "#DEE3E9";
      className = "black";
      textNode = textNodeWhite.replace("{{COLOR}}","#DEE3E9");
      break;
    case "/join-us":
      bgColor = "#FFFFFF";
      className = "black";
      textNode = textNodeWhite.replace("{{COLOR}}","#FFFFFF");
      break;
    case "/collaborate":
      className = "";
      bgColor = "#060606";
      break;
  }
  styleTag.appendChild(document.createTextNode(textNode));
  document.getElementsByTagName("head")[0].appendChild(styleTag)
  return (
    <React.Fragment>
      <div className={className} style={{ background: bgColor }}>
        <Router>
          <AnimatedRoutes />
        </Router>
      </div>
    </React.Fragment>
  );
}

export default App;
