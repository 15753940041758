import React, { useEffect, useRef } from "react";
import "./home.style.css";
import VideoPlayer from "../../components/videoplayer/videoplayer";
import Button from "../../components/button/button";
import ProjectCard from "../home/project.card";
import ExpertiseSection from "../home/expertise.index";
import TechnoSlider from "./techno.slider";
import Testimonial from "./testimonial.slider";
import BigArrow from "../../assets/images/big_arrow.svg";
import Footer from "../../components/footer/footer";
import {
  motion,
  useInView,
  useAnimation,
} from "framer-motion/dist/framer-motion";
import companyProfile from '../../companyProfile.pdf';

const home = () => {
  const ref1 = useRef(null);
  const ref2 = useRef(null);
  const ref3 = useRef(null);
  const ref4 = useRef(null);
  const ref5 = useRef(null);
  const ref6 = useRef(null);
  const ref7 = useRef(null);
  const ref8 = useRef(null);
  const ref9 = useRef(null);
  const isInView1 = useInView(ref1);
  const isInView2 = useInView(ref2);
  const isInView3 = useInView(ref3);
  const isInView4 = useInView(ref4);
  const isInView5 = useInView(ref5);
  const isInView6 = useInView(ref6);
  const isInView7 = useInView(ref7);
  const isInView8 = useInView(ref8);
  const isInView9 = useInView(ref9);

  const Controls1 = useAnimation();
  const Controls2 = useAnimation();
  const Controls3 = useAnimation();
  const Controls4 = useAnimation();
  const Controls5 = useAnimation();
  const Controls6 = useAnimation();
  const Controls7 = useAnimation();
  const Controls8 = useAnimation();
  const Controls9 = useAnimation();

  useEffect(() => {
    if (isInView1) {
      Controls1.start("visible");
    } else {
      Controls1.start("hidden");
    }
  }, [isInView1]);

  useEffect(() => {
    if (isInView2) {
      Controls2.start("visible");
    } else {
      Controls2.start("hidden");
    }
  }, [isInView2]);
  useEffect(() => {
    if (isInView3) {
      Controls3.start("visible");
    } else {
      Controls3.start("hidden");
    }
  }, [isInView3]);
  useEffect(() => {
    if (isInView4) {
      Controls4.start("visible");
    } else {
      Controls4.start("hidden");
    }
  }, [isInView4]);
  useEffect(() => {
    if (isInView5) {
      Controls5.start("visible");
    } else {
      Controls5.start("hidden");
    }
  }, [isInView5]);
  useEffect(() => {
    if (isInView6) {
      Controls6.start("visible");
    } else {
      Controls6.start("hidden");
    }
  }, [isInView6]);
  useEffect(() => {
    if (isInView7) {
      Controls7.start("visible");
    } else {
      Controls7.start("hidden");
    }
  }, [isInView7]);
  useEffect(() => {
    if (isInView8) {
      Controls8.start("visible");
    } else {
      Controls8.start("hidden");
    }
  }, [isInView8]);
  useEffect(() => {
    if (isInView9) {
      Controls9.start("visible");
    } else {
      Controls9.start("hidden");
    }
  }, [isInView9]);

  const variants1 = {
    hidden: { opacity: 0, y: 80 },
    visible: { opacity: 1, y: 0 },
  };
  const variantsHead = {
    hidden: { opacity: 0, y: 90 },
    visible: { opacity: 1, y: 0 },
  };
  const variants2 = {
    visible: { opacity: 1, x: 0 },
    hidden: { opacity: 0, x: "-10vh" },
  };

  const variants3 = {
    visible: { opacity: 1, x: 0 },
    hidden: { opacity: 0, x: "-10vh" },
  };

  const variants4 = {
    visible: { opacity: 1, x: 0 },
    hidden: { opacity: 0, x: "-10vh" },
  };
  const variants5 = {
    visible: { opacity: 1, x: 0 },
    hidden: { opacity: 0, x: "-10vh" },
  };

  const variants6 = {
    visible: { opacity: 1, x: 0 },
    hidden: { opacity: 0, x: "-10vh" },
  };
  const variants7 = {
    visible: { opacity: 1, x: 0 },
    hidden: { opacity: 0, x: "-10vh" },
  };

  const variants8 = {
    visible: { opacity: 1, x: 0 },
    hidden: { opacity: 0, x: "-10vh" },
  };
  const variants9 = {
    visible: { opacity: 1, x: 0 },
    hidden: { opacity: 0, x: "-10vh" },
  };

  const handleDownload = () => {
    const link = document.createElement('a');
    link.download = 'companyProfile-PDF';

    link.href = companyProfile;

    link.click();
  };


  return (
    <motion.div
      className="home_wrapper"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      <div className="container">
        <div className="main_wrapper">
          <motion.div
            ref={ref1}
            variants={variants1}
            initial="hidden"
            animate={Controls1}
            transition={{
              ease: "easeInOut",
              duration: 1,
              delay: 0.4,
            }}
          >
            <img src={BigArrow} alt="bigArrow"></img>
          </motion.div>
          <motion.div
            ref={ref1}
            variants={variantsHead}
            initial="hidden"
            animate={Controls1}
            transition={{
              ease: "easeInOut",
              duration: 1,
              delay: 0.6,
            }}
          >
            <h1>
              Digital solutions driving design and tech for a brighter tomorrow
            </h1>
          </motion.div>
        </div>

        <VideoPlayer />

        <div className="about_wrapper">
          <motion.h2
            ref={ref2}
            variants={variants2}
            initial="hidden"
            animate={Controls2}
            // whileInView="visible"
            viewport={{ once: true }}
            transition={{ type: "spring", duration: 1 }}
          >
            DoubleDotts Labs is a unique digital agency who solving problems &
            create user friendly design and build creative product.
          </motion.h2>
          <a href="/culture" className="culture_navigate">
            <Button
              text="About DoubleDotts Labs"
              className="main_btn"
              icon={
                <svg
                  className="icon_svg"
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M6 14L14 6M14 6H6M14 6V14"
                    stroke=""
                    strokeLinecap="square"
                  />
                </svg>
              }
            />
          </a>
        </div>

        <div className="project_wrapper">
          <motion.div
            className="sub_heading"
            ref={ref3}
            variants={variants3}
            initial="hidden"
            animate={Controls3}
            whileInView="visible"
            viewport={{ once: true }}
            transition={{ duration: 1, delay: 0.4 }}
          >
            We do great things.
          </motion.div>
          <motion.h1
            ref={ref4}
            variants={variants4}
            initial="hidden"
            animate={Controls4}
            whileInView="visible"
            viewport={{ once: true }}
            transition={{ duration: 1, delay: 0.6 }}
          >
            Selected Projects
          </motion.h1>
          <div className="project_detail">
            <ProjectCard />
            <Button
              text="Download company profile"
              className="main_btn"
              icon={
                <svg
                  className="icon_svg"
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M6 14L14 6M14 6H6M14 6V14"
                    stroke=""
                    strokeLinecap="square"
                  />
                </svg>
              }
              onClick={handleDownload}
              variant="contained"
            />
          </div>
        </div>

        <div className="expertise_wrapper">
          <motion.div
            className="sub_heading"
            ref={ref5}
            variants={variants5}
            initial="hidden"
            animate={Controls5}
            whileInView="visible"
            viewport={{ once: true }}
            transition={{ duration: 1, delay: 0.4 }}
          >
            What we are good at.
          </motion.div>
          <motion.h1
            ref={ref6}
            variants={variants6}
            initial="hidden"
            animate={Controls6}
            whileInView="visible"
            viewport={{ once: true }}
            transition={{ duration: 1, delay: 0.4 }}
          >
            Harnessing human expertise and emerging technology for tailored
            experiences
          </motion.h1>
          <ExpertiseSection />
        </div>

        <div className="technologies_wrapper">
          <motion.div
            className="sub_heading"
            ref={ref7}
            variants={variants7}
            initial="hidden"
            animate={Controls7}
            whileInView="visible"
            viewport={{ once: true }}
            transition={{ duration: 1, delay: 0.4 }}
          >
            Technologies we knows.
          </motion.div>
          <motion.h1
            ref={ref8}
            variants={variants8}
            initial="hidden"
            animate={Controls8}
            whileInView="visible"
            viewport={{ once: true }}
            transition={{ duration: 1, delay: 0.4 }}
          >
            Our engineers possess a wide range of expertise in various
            technologies
          </motion.h1>
          <div className="techno">
            <TechnoSlider />
          </div>
        </div>

        {/* <div className="testimonial_wrapper">
          <motion.div
            className="sub_heading"
            ref={ref9}
            variants={variants9}
            initial="hidden"
            animate={Controls9}
            whileInView="visible"
            viewport={{ once: true }}
            transition={{ duration: 1, delay: 0.4 }}
          >
            What our Clients says
          </motion.div>
          <Testimonial />
        </div> */}

        <Footer />
      </div>
    </motion.div>
  );
};

export default home;
