import React, { useEffect, useRef } from "react";
import "./home.style.css";
import ReactJsIcon from "../../assets/images/techno_icon/React JS.svg";
import NodeJsIcon from "../../assets/images/techno_icon/Node JS.svg";
import AngularIcon from "../../assets/images/techno_icon/Angular.svg";
import NestJSIcon from "../../assets/images/techno_icon/Nest JS.svg";
import GRPCIcon from "../../assets/images/techno_icon/gRPC.svg";
import RebbitMqIcon from "../../assets/images/techno_icon/Rebbit Mq.svg";
import RedisIcon from "../../assets/images/techno_icon/Redis.svg";
import MicroserviceIcon from "../../assets/images/techno_icon/Microservice Architecture.svg";
import NextJSIcon from "../../assets/images/techno_icon/Next JS.svg";
import SupabaseIcon from "../../assets/images/techno_icon/Supabase.svg";
import PostgresIcon from "../../assets/images/techno_icon/Postgres.svg";
import ClickhouseDBIcon from "../../assets/images/techno_icon/ClickhouseDB.svg";
import MySQLIcon from "../../assets/images/techno_icon/MySQL.svg";
import MongoDBIcon from "../../assets/images/techno_icon/MongoDB.svg";
import PhpIcon from "../../assets/images/techno_icon/Php.svg";
import LaravelIcon from "../../assets/images/techno_icon/Laravel.svg";
import codignatorIcon from "../../assets/images/techno_icon/codignator.svg";
import AWSIcon from "../../assets/images/techno_icon/AWS.svg";
import DigitalOcianIcon from "../../assets/images/techno_icon/DigitalOcian.svg";
import AZUREIcon from "../../assets/images/techno_icon/AZURE.svg";
import OVHIcon from "../../assets/images/techno_icon/OVH.svg";
import FlutterIcon from "../../assets/images/techno_icon/Flutter.svg";
import KotlinIcon from "../../assets/images/techno_icon/Kotlin.svg";
import DartIcon from "../../assets/images/techno_icon/Dart.svg";
import JAVAIcon from "../../assets/images/techno_icon/JAVA.svg";
import AndroidStudioIcon from "../../assets/images/techno_icon/Android Studio.svg";
import FirebaseIcon from "../../assets/images/techno_icon/Firebase.svg";
import WebflowIcon from "../../assets/images/techno_icon/Webflow.svg";
import FigmaIcon from "../../assets/images/techno_icon/Figma.svg";
import PhotoshopIcon from "../../assets/images/techno_icon/Photoshop.svg";
import IllustratorIcon from "../../assets/images/techno_icon/Illustrator.svg";
import AdobeXDIcon from "../../assets/images/techno_icon/Adobe XD.svg";
import MiroIcon from "../../assets/images/techno_icon/Miro.svg";
import SketchIcon from "../../assets/images/techno_icon/Sketch.svg";
import {
  motion,
  useInView,
  useAnimation,
} from "framer-motion/dist/framer-motion";

const TechnoSlider = () => {
  const iconItem = [
    {
      id: 1,
      icon: ReactJsIcon,
      name: "React Js",
    },
    {
      id: 2,
      icon: NodeJsIcon,
      name: "Node JS",
    },
    {
      id: 3,
      icon: AngularIcon,
      name: "Angular",
    },
    {
      id: 4,
      icon: NestJSIcon,
      name: "Nest JS",
    },
    {
      id: 5,
      icon: GRPCIcon,
      name: "gRPC",
    },
    {
      id: 6,
      icon: RebbitMqIcon,
      name: "Rebbit Mq",
    },
    {
      id: 7,
      icon: RedisIcon,
      name: "Redis",
    },
    {
      id: 8,
      icon: MicroserviceIcon,
      name: "Microservice Architecture",
    },
    {
      id: 9,
      icon: NextJSIcon,
      name: "Next JS",
    },
    {
      id: 10,
      icon: SupabaseIcon,
      name: "Supabase",
    },
    {
      id: 11,
      icon: PostgresIcon,
      name: "Postgres",
    },
    {
      id: 12,
      icon: ClickhouseDBIcon,
      name: "Click house DB",
    },
    {
      id: 13,
      icon: MySQLIcon,
      name: "MySQL",
    },
    {
      id: 14,
      icon: MongoDBIcon,
      name: "MongoDB",
    },
    {
      id: 15,
      icon: PhpIcon,
      name: "Php",
    },
    {
      id: 16,
      icon: LaravelIcon,
      name: "Laravel",
    },
    {
      id: 17,
      icon: codignatorIcon,
      name: "codignator",
    },
    {
      id: 18,
      icon: AWSIcon,
      name: "AWS",
    },
    {
      id: 19,
      icon: DigitalOcianIcon,
      name: "DigitalOcian",
    },
    {
      id: 20,
      icon: AZUREIcon,
      name: "AZURE",
    },
    {
      id: 21,
      icon: OVHIcon,
      name: "OVH",
    },
    {
      id: 22,
      icon: FlutterIcon,
      name: "Flutter",
    },
    {
      id: 23,
      icon: KotlinIcon,
      name: "Kotlin",
    },
    {
      id: 24,
      icon: DartIcon,
      name: "Dart",
    },
    {
      id: 25,
      icon: JAVAIcon,
      name: "JAVA",
    },
    {
      id: 26,
      icon: AndroidStudioIcon,
      name: "Android Studio",
    },
    {
      id: 27,
      icon: FirebaseIcon,
      name: "Firebase",
    },
    {
      id: 28,
      icon: WebflowIcon,
      name: "Webflow",
    },
    {
      id: 29,
      icon: FigmaIcon,
      name: "Figma",
    },
    {
      id: 30,
      icon: PhotoshopIcon,
      name: "Photoshop",
    },
    {
      id: 31,
      icon: IllustratorIcon,
      name: "Illustrator",
    },
    {
      id: 32,
      icon: AdobeXDIcon,
      name: "Adobe XD",
    },
    {
      id: 33,
      icon: MiroIcon,
      name: "Miro",
    },
    {
      id: 34,
      icon: SketchIcon,
      name: "Sketch",
    },
  ];

  return (
    <div className="rainbowGradient">
      <div className="techno_wrapper">
        {/* <div className="container"> */}
        <ul>
          {iconItem.map((icon) => {
            return (
              <li key={icon.id}>
                <img src={icon.icon} alt={icon.name} className="" />
              </li>
            );
          })}
        </ul>
        {/* </div> */}
      </div>
    </div>
  );
};

export default TechnoSlider;
