import React, { useEffect, useRef } from "react";
import { motion } from "framer-motion/dist/framer-motion";
import "./joinus.style.css";
import joinus_1 from "../../assets/images/joinus_img/joinus_1.png";
import joinus_2 from "../../assets/images/joinus_img/joinus_2.png";
import Button from "../../components/button/button";
import Footer from "../../components/footer/footer";
import { useAnimation, useInView } from "framer-motion/dist/framer-motion";
import Rope from "../../components/rope/rope";

const JoinUs = () => {
  const ref1 = useRef(null);
  const ref2 = useRef(null);
  const ref3 = useRef(null);
  const ref4 = useRef(null);
  const ref5 = useRef(null);
  const ref6 = useRef(null);
  const ref7 = useRef(null);

  const Controls1 = useAnimation();
  const Controls2 = useAnimation();
  const Controls3 = useAnimation();
  const Controls4 = useAnimation();
  const Controls5 = useAnimation();
  const Controls6 = useAnimation();
  const Controls7 = useAnimation();

  const isInView1 = useInView(ref1);
  const isInView2 = useInView(ref2);
  const isInView3 = useInView(ref3);
  const isInView4 = useInView(ref4);
  const isInView5 = useInView(ref5);
  const isInView6 = useInView(ref6);
  const isInView7 = useInView(ref7);

  useEffect(() => {
    if (isInView1) {
      Controls1.start("visible");
    } else {
      Controls1.start("hidden");
    }
  }, [isInView1]);

  useEffect(() => {
    if (isInView2) {
      Controls2.start("visible");
    } else {
      Controls2.start("hidden");
    }
  }, [isInView2]);

  useEffect(() => {
    if (isInView3) {
      Controls3.start("visible");
    } else {
      Controls3.start("hidden");
    }
  }, [isInView3]);
  useEffect(() => {
    if (isInView4) {
      Controls4.start("visible");
    } else {
      Controls4.start("hidden");
    }
  }, [isInView4]);
  useEffect(() => {
    if (isInView5) {
      Controls5.start("visible");
    } else {
      Controls5.start("hidden");
    }
  }, [isInView5]);
  useEffect(() => {
    if (isInView6) {
      Controls6.start("visible");
    } else {
      Controls6.start("hidden");
    }
  }, [isInView6]);
  useEffect(() => {
    if (isInView7) {
      Controls7.start("visible");
    } else {
      Controls7.start("hidden");
    }
  }, [isInView7]);

  const variantsHead = {
    hidden: { opacity: 0, y: 90 },
    visible: { opacity: 1, y: 5 },
  };
  const variants1 = {
    visible: {
      opacity: 1,
      y: 0,
      transition: { duration: 0.9, delay: 0.3 },
    },
    hidden: { opacity: 0, y: "10vh" },
  };
  const variants2 = {
    visible: {
      opacity: 1,
      y: 0,
      transition: { duration: 0.9, delay: 0.5 },
    },
    hidden: { opacity: 0, y: "10vh" },
  };
  const variants3 = {
    hidden: { opacity: 0, x: "-10vh" },
    visible: { opacity: 1, x: 0 },
  };
  const variants4 = {
    visible: { opacity: 1, scale: 1, rotate: 0 },
    hidden: { opacity: 0, scale: 0, rotate: 14 },
  };
  const variants5 = {
    visible: { opacity: 1, scale: 1, rotate: 0 },
    hidden: { opacity: 0, scale: 0, rotate: 14 },
  };
  const variants6 = {
    visible: { opacity: 1, scale: 1, rotate: 0 },
    hidden: { opacity: 0, scale: 0, rotate: 14 },
  };
  return (
    <>
      <div className="bg_colour">
        <div className="container">
          <div>
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
            >
              <motion.h1
                className="main"
                ref={ref1}
                variants={variantsHead}
                initial="hidden"
                animate={Controls1}
                transition={{
                  ease: "easeInOut",
                  duration: 1,
                }}
              >
                Careers
              </motion.h1>
            </motion.div>
          </div>
          <div className="row ">
            <motion.div
              className="col-8 joinus_img_div"
              ref={ref2}
              variants={variants1}
              initial="hidden"
              animate={Controls2}
              viewport={{ once: true }}
            >
              <img src={joinus_1} alt="img" className="img-fluid joinus_img" />
            </motion.div>
            <motion.div
              className="col-4 joinus_img_div"
              ref={ref3}
              variants={variants2}
              initial="hidden"
              animate={Controls3}
              viewport={{ once: true }}
            >
              <img src={joinus_2} alt="img" className="img-fluid joinus_img" />
            </motion.div>
          </div>

          <motion.h1
            className="joinus_wrapper"
            ref={ref4}
            variants={variants3}
            initial="hidden"
            animate={Controls4}
            transition={{
              ease: "easeInOut",
              duration: 0.7,
            }}
          >
            We give vibrant existence to courageous concepts, enabling you to
            experience and embrace them.
          </motion.h1>

          <div className="hr">
        <Rope />
        </div>

          <motion.div
            ref={ref5}
            initial="hidden"
            transition={{ duration: 0.8 }}
            animate={Controls5}
            variants={variants4}
          >
            <div className="main_joinus">
              <p className="joinus_wrapper1 col-5">Operation</p>
              <div className="col-7 designer">
                <div className="gap_div">
                  <p className="joinus_wrapper2">Senior project manager</p>
                  <div className="career_exp">
                    <p>Remote</p>
                    <span></span>
                    <p>Full time</p>
                    <span></span>
                    <p>4+ year experience</p>
                  </div>
                </div>

                <Button
                  text="Open"
                  className="joinus_btn"
                  icon={
                    <svg
                      className="icon_svg"
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M6 14L14 6M14 6H6M14 6V14"
                        stroke=""
                        strokeLinecap="square"
                      />
                    </svg>
                  }
                />
              </div>
            </div>

            <div className="hr_1"></div>
          </motion.div>
          <motion.div
            ref={ref6}
            initial="hidden"
            transition={{ duration: 0.8 }}
            animate={Controls6}
            variants={variants5}
          >
            <div className="main_joinus">
              <p className="joinus_wrapper1 col-5">Design</p>
              <div className="col-7 designerr">
                <div className="designerr_div">
                  <div className="gap_div">
                    <p className="joinus_wrapper2">Senior product designer</p>
                    <div className="career_exp">
                      <p>Onsite</p>
                      <span></span>
                      <p>Full time</p>
                      <span></span>
                      <p>3+ year experience</p>
                    </div>
                  </div>
                  <Button
                    text="Open"
                    className="joinus_btn"
                    icon={
                      <svg
                        className="icon_svg"
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M6 14L14 6M14 6H6M14 6V14"
                          stroke=""
                          strokeLinecap="square"
                        />
                      </svg>
                    }
                  />
                </div>

                <div className="designerr_div">
                  <div className="gap_div">
                    <p className="joinus_wrapper2">Junior UI designer</p>
                    <div className="career_exp">
                      <p>Onsite</p>
                      <span></span>
                      <p>Full time</p>
                      <span></span>
                      <p>1+ year experience</p>
                    </div>
                  </div>
                  <Button
                    text="Open"
                    className="joinus_btn"
                    icon={
                      <svg
                        className="icon_svg"
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M6 14L14 6M14 6H6M14 6V14"
                          stroke=""
                          strokeLinecap="square"
                        />
                      </svg>
                    }
                  />
                </div>
              </div>
            </div>

            <div className="hr_1"></div>
          </motion.div>

          <motion.div
          className="joinus_last"
            ref={ref7}
            initial="hidden"
            transition={{ duration: 0.8 }}
            animate={Controls7}
            variants={variants6}
          >
            <div className="main_joinus">
              <p className="joinus_wrapper1 col-5">Development</p>
              <div className="col-7 designerr">
                <div className="designerr_div">
                  <div className="gap_div">
                    <p className="joinus_wrapper2">Senior flutter developer</p>
                    <div className="career_exp">
                      <p>Onsite</p>
                      <span></span>
                      <p>Full time</p>
                      <span></span>
                      <p>4+ year experience</p>
                    </div>
                  </div>
                  <Button
                    text="Open"
                    className="joinus_btn"
                    icon={
                      <svg
                        className="icon_svg"
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M6 14L14 6M14 6H6M14 6V14"
                          stroke=""
                          strokeLinecap="square"
                        />
                      </svg>
                    }
                  />
                </div>

                <div className="designerr_div">
                  <div className="gap_div">
                    <p className="joinus_wrapper2">Junior react JS developer (2)</p>
                    <div className="career_exp">
                      <p>Onsite</p>
                      <span></span>
                      <p>Full time</p>
                      <span></span>
                      <p>Fresher</p>
                    </div>
                  </div>
                  <Button
                    text="Open"
                    className="joinus_btn"
                    icon={
                      <svg
                        className="icon_svg"
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M6 14L14 6M14 6H6M14 6V14"
                          stroke=""
                          strokeLinecap="square"
                        />
                      </svg>
                    }
                  />
                </div>
              </div>
            </div>
          </motion.div>
          <Footer />
        </div>
      </div>
    </>
  );
};

export default JoinUs;
