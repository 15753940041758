import React, { useState, useEffect, useRef } from "react";
import videoFile from "../../assets/images/video.png";
import playButtonIcon from "../../assets/images/play.svg";
import "./video.style.css";
import {
  motion,
  useInView,
  useAnimation,
} from "framer-motion/dist/framer-motion";

const VideoPlayer = () => {
  const videoRef = useRef(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const togglePlay = () => {
    const video = videoRef.current;
    if (isPlaying) {
      video.pause();
    } else {
      video.play();
    }
    setIsPlaying(!isPlaying);
  };
  const ref = useRef(null);
  const mainControls = useAnimation();
  const isInView = useInView(ref);
  useEffect(() => {
    // console.log("fff", isInView);
    if (isInView) {
      //fire the animation
      mainControls.start("visible");
    } else {
      mainControls.start("hidden");
    }
  }, [isInView]);

  const variants = {
    visible: { opacity: 1, x: 0, scale: 1 },
    hidden: { opacity: 0, x: 100, scale: 0 },
  };

  return (
    <div className="video_wrapper">
      <motion.div
        ref={ref}
        variants={variants}
        initial="hidden"
        animate={mainControls}
        transition={{ duration: 0.6, delay: 0.2 }}
      >
        <img
          ref={videoRef}
          src={videoFile}
          className="video_img"
          controls
        ></img>
        <button className="play_btn" onClick={togglePlay}>
          <span>
            <svg viewBox="0 0 100 100">
              <defs>
                <path
                  id="circle"
                  d="
        M 50, 50
        m -37, 0
        a 37,37 0 1,1 74,0
        a 37,37 0 1,1 -74,0"
                />
              </defs>
              <g className="circle">
                <text fontSize="16">
                  <textPath xlinkHref="#circle">
                    play now &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;show reel
                  </textPath>
                </text>
              </g>
            </svg>
          </span>
          {isPlaying ? (
            <img src={playButtonIcon} alt="pause Button" />
          ) : (
            <img src={playButtonIcon} alt="Play Button" />
          )}
        </button>
      </motion.div>
    </div>
  );
};

export default VideoPlayer;
