import React from "react";
import { motion } from "framer-motion/dist/framer-motion";
import "./project.style.css";
import Work_1 from "../../assets/images/works_img/work_1.png";
import Work_2 from "../../assets/images/works_img/work_2.png";
import Work_3 from "../../assets/images/works_img/work_3.png";
import Work_4 from "../../assets/images/works_img/work_4.png";
import Work_5 from "../../assets/images/works_img/work_5.png";
import Work_6 from "../../assets/images/works_img/work_6.png";
import Work_7 from "../../assets/images/works_img/work_7.png";
import Work_8 from "../../assets/images/works_img/work_8.png";
import Work_9 from "../../assets/images/works_img/work_9.png";

import Footer from "../../components/footer/footer";
import { useInView, useAnimation } from "framer-motion/dist/framer-motion";
import { useRef, useEffect } from "react";

const Work = () => {
  const ref1 = useRef(null);
  const ref2 = useRef(null);
  const ref3 = useRef(null);
  const ref4 = useRef(null);
  const ref5 = useRef(null);
  const ref6 = useRef(null);
  const ref7 = useRef(null);
  const ref8 = useRef(null);
  const ref9 = useRef(null);
  const ref10 = useRef(null);
  const Controls1 = useAnimation();
  const Controls2 = useAnimation();
  const Controls3 = useAnimation();
  const Controls4 = useAnimation();
  const Controls5 = useAnimation();
  const Controls6 = useAnimation();
  const Controls7 = useAnimation();
  const Controls8 = useAnimation();
  const Controls9 = useAnimation();
  const Controls10 = useAnimation();
  const isInView1 = useInView(ref1);
  const isInView2 = useInView(ref2);
  const isInView3 = useInView(ref3);
  const isInView4 = useInView(ref4);
  const isInView5 = useInView(ref5);
  const isInView6 = useInView(ref6);
  const isInView7 = useInView(ref7);
  const isInView8 = useInView(ref8);
  const isInView9 = useInView(ref9);
  const isInView10 = useInView(ref10);

  useEffect(() => {
    if (isInView1) {
      Controls1.start("visible");
    } else {
      Controls1.start("hidden");
    }
  }, [isInView1]);
  useEffect(() => {
    if (isInView2) {
      Controls2.start("visible");
    } else {
      Controls2.start("hidden");
    }
  }, [isInView2]);
  useEffect(() => {
    if (isInView3) {
      Controls3.start("visible");
    } else {
      Controls3.start("hidden");
    }
  }, [isInView3]);
  useEffect(() => {
    if (isInView4) {
      Controls4.start("visible");
    } else {
      Controls4.start("hidden");
    }
  }, [isInView4]);
  useEffect(() => {
    if (isInView5) {
      Controls5.start("visible");
    } else {
      Controls5.start("hidden");
    }
  }, [isInView5]);
  useEffect(() => {
    if (isInView6) {
      Controls6.start("visible");
    } else {
      Controls6.start("hidden");
    }
  }, [isInView6]);
  useEffect(() => {
    if (isInView7) {
      Controls7.start("visible");
    } else {
      Controls7.start("hidden");
    }
  }, [isInView7]);
  useEffect(() => {
    if (isInView8) {
      Controls8.start("visible");
    } else {
      Controls8.start("hidden");
    }
  }, [isInView8]);
  useEffect(() => {
    if (isInView9) {
      Controls9.start("visible");
    } else {
      Controls9.start("hidden");
    }
  }, [isInView9]);
  useEffect(() => {
    if (isInView10) {
      Controls10.start("visible");
    } else {
      Controls10.start("hidden");
    }
  }, [isInView10]);

  const variants1 = {
    hidden: { opacity: 0, y: 80 },
    visible: { opacity: 1, y: 0 },
  };

  const variantsHead = {
    hidden: { opacity: 0, y: 90 },
    visible: { opacity: 1, y: 0 },
  };
  const variants2 = {
    visible: {
      opacity: 1,
      y: 0,
      transition: { duration: 0.9, delay: 0.3 },
    },
    hidden: { opacity: 0, y: "10vh" },
  };
  const variants3 = {
    visible: {
      opacity: 1,
      y: 0,
      transition: { duration: 0.9, delay: 0.5 },
    },
    hidden: { opacity: 0, y: "10vh" },
  };
  const variants4 = {
    visible: {
      opacity: 1,
      y: 0,
      transition: { duration: 0.9, delay: 0.7 },
    },
    hidden: { opacity: 0, y: "10vh" },
  };
  const variants5 = {
    visible: {
      opacity: 1,
      y: 0,
      transition: { duration: 0.9, delay: 0.3 },
    },
    hidden: { opacity: 0, y: "10vh" },
  };
  const variants6 = {
    visible: {
      opacity: 1,
      y: 0,
      transition: { duration: 0.9, delay: 0.5 },
    },
    hidden: { opacity: 0, y: "10vh" },
  };
  const variants7 = {
    visible: {
      opacity: 1,
      y: 0,
      transition: { duration: 0.9, delay: 0.7 },
    },
    hidden: { opacity: 0, y: "10vh" },
  };
  const variants8 = {
    visible: {
      opacity: 1,
      y: 0,
      transition: { duration: 0.9, delay: 0.3 },
    },
    hidden: { opacity: 0, y: "10vh" },
  };
  const variants9 = {
    visible: {
      opacity: 1,
      y: 0,
      transition: { duration: 0.9, delay: 0.5 },
    },
    hidden: { opacity: 0, y: "10vh" },
  };
  const variants10 = {
    visible: {
      opacity: 1,
      y: 0,
      transition: { duration: 0.9, delay: 0.7 },
    },
    hidden: { opacity: 0, y: "10vh" },
  };
  return (
    <div className="bg-color">
      <div className="container">
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
        >
          <motion.h1
            className="main_heading"
            ref={ref1}
            variants={variants1}
            initial="hidden"
            animate={Controls1}
            transition={{
              ease: "easeInOut",
              duration: 1,
              delay: 0.4,
            }}
          >
            Selected
          </motion.h1>
        </motion.div>
        <motion.div
          className="text-center h1_img"
          ref={ref1}
          variants={variantsHead}
          initial="hidden"
          animate={Controls1}
          transition={{
            ease: "easeInOut",
            duration: 1,
            delay: 0.6,
          }}
        >
          <img src={Work_1} alt="" className="img-fluid" />
          <h1 className="h1">Works</h1>
        </motion.div>

        <div className="row work_wrapper">
          <motion.div
            className="col-sm-12 col-md-6 col-lg-4"
            ref={ref2}
            initial="hidden"
            transition={{ duration: 0.8 }}
            animate={Controls2}
            variants={variants2}
          >
            <div className="work_div img_1">
              <div className="div_position">
                <h5>Hubber</h5>
                <span>UI UX Design, Web Development</span>
              </div>
              <img src={Work_2} alt="" className="img-fluid" />
            </div>
          </motion.div>
          <motion.div
            className="col-sm-12 col-md-6 col-lg-4"
            ref={ref3}
            initial="hidden"
            transition={{ duration: 0.8 }}
            animate={Controls3}
            variants={variants3}
          >
            <div className="work_div img_2">
              <div className="div_position">
                <h5>Tendex (Chrome Extension)</h5>
                <span>UI UX Design</span>
              </div>
              <img src={Work_3} alt="" className="img-fluid" />
            </div>
          </motion.div>
          <motion.div
            className="col-sm-12 col-md-6 col-lg-4"
            ref={ref4}
            initial="hidden"
            transition={{ duration: 0.8 }}
            animate={Controls4}
            variants={variants4}
          >
            <div className="work_div img_3">
              <div className="div_position">
                <h5>Onquity</h5>
                <span>UI UX Design</span>
              </div>
              <img src={Work_4} alt="" className="img-fluid" />
            </div>
          </motion.div>
          <motion.div
            className="col-sm-12 col-md-6 col-lg-4"
            ref={ref5}
            initial="hidden"
            transition={{ duration: 0.8 }}
            animate={Controls5}
            variants={variants5}
          >
            <div className="work_div img_4">
              <div className="div_position">
                <h5>RTSN</h5>
                <span>UI UX Design</span>
              </div>
            </div>
          </motion.div>
          <motion.div
            className="col-sm-12 col-md-6 col-lg-4"
            ref={ref6}
            initial="hidden"
            transition={{ duration: 0.8 }}
            animate={Controls6}
            variants={variants6}
          >
            <div className="work_div img_5">
              <div className="div_position">
                <h5>Kashyap Impax</h5>
                <span>UI UX Design, Web Development</span>
              </div>
              <img src={Work_5} alt="" className="img-fluid" />
            </div>
          </motion.div>
          <motion.div
            className="col-sm-12 col-md-6 col-lg-4"
            ref={ref7}
            initial="hidden"
            transition={{ duration: 0.8 }}
            animate={Controls7}
            variants={variants7}
          >
            <div className="work_div img_6">
              <div className="div_position">
                <h5>WakaTicket</h5>
                <span>Web and App Development</span>
              </div>
              <img src={Work_6} alt="" className="img-fluid" />
            </div>
          </motion.div>
          <motion.div
            className="col-sm-12 col-md-6 col-lg-4"
            ref={ref8}
            initial="hidden"
            transition={{ duration: 0.8 }}
            animate={Controls8}
            variants={variants8}
          >
            <div className="work_div img_7">
              <div className="div_position">
                <h5>Alpha10X</h5>
                <span>UI UX Design</span>
              </div>
              <img src={Work_7} alt="" className="img-fluid" />
            </div>
          </motion.div>
          <motion.div
            className="col-sm-12 col-md-6 col-lg-4"
            ref={ref9}
            initial="hidden"
            transition={{ duration: 0.8 }}
            animate={Controls9}
            variants={variants9}
          >
            <div className="work_div img_8">
              <div className="div_position">
                <h5>Creditzoop</h5>
                <span>Web Development</span>
              </div>
              <img src={Work_8} alt="" className="img-fluid" />
            </div>
          </motion.div>
          <motion.div
            className="col-sm-12 col-md-6 col-lg-4"
            ref={ref10}
            initial="hidden"
            transition={{ duration: 0.8 }}
            animate={Controls10}
            variants={variants10}
          >
            <div className="work_div img_9">
              <div className="div_position">
                <h5>MainQuest</h5>
                <span>UI UX Design</span>
              </div>
              <img src={Work_9} alt="" className="img-fluid" />
            </div>
          </motion.div>
        </div>
        <Footer />
      </div>
    </div>
  );
};

export default Work;
