import React, { useEffect, useRef } from "react";
import "./rope.style.css";
import TWEEN from "tween.js";
import {
  motion,
  useInView,
  useAnimation,
} from "framer-motion/dist/framer-motion";

function Rope() {
  const ref2 = useRef(null);
  const isInView2 = useInView(ref2);
  const Controls2 = useAnimation();
  useEffect(() => {
    if (isInView2) {
      Controls2.start("visible");
    } else {
      Controls2.start("hidden");
    }
  }, [isInView2]);

  const variants2 = {
    visible: { opacity: 1, x: 0 },
    hidden: { opacity: 0, x: "-25vh" },
  };

  const svgRef = useRef(null);
  const devRef = useRef(null);
  const pathRef = useRef(null);
  const connectedRef = useRef(false);
  const tweeningRef = useRef(false);
  const mousePosRef = useRef({ y: 0 });
  const svgTopRef = useRef(0);
  let tweenRef = useRef(null);

  useEffect(() => {
    const svgElement = svgRef.current;
    const divElement = devRef.current;
    const path = pathRef.current;

    const setSVGTop = () => {
      const svgTop = svgElement.getBoundingClientRect().top;
      svgTopRef.current = svgTop;
    };

    const addListeners = () => {
      const handleMouseMove = (e) => {
        mousePosRef.current.y = e.clientY - svgTopRef.current;
      };

      const handleResize = () => {
        setSVGTop();
      };

      const handleMouseOver = () => {
        if (!connectedRef.current && !tweeningRef.current) {
          connectedRef.current = true;
          svgElement.style.cursor = "pointer";
        }
      };

      svgElement.addEventListener("mouseenter", setSVGTop);
      window.addEventListener("mousemove", handleMouseMove);
      window.addEventListener("resize", handleResize);
      path.addEventListener("mouseover", handleMouseOver);

      return () => {
        svgElement.removeEventListener("mouseenter", setSVGTop);
        window.removeEventListener("mousemove", handleMouseMove);
        window.removeEventListener("resize", handleResize);
        path.removeEventListener("mouseover", handleMouseOver);
      };
    };

    const updateCurve = () => {
      const y = mousePosRef.current.y;
      const newY = y - (100 - y) * 1.1;
      if (Math.abs(100 - newY) > 96) {
        connectedRef.current = false;
        tweeningRef.current = true;
        svgElement.style.cursor = "default";
        snapBack(newY);
      } else {
        path.setAttribute("d", `M0,100 Q640,${newY} 1280,100`);
      }
    };

    const snapBack = (y) => {
      tweenRef.current = new TWEEN.Tween({ y })
        .to({ y: 100 }, 800)
        .easing(TWEEN.Easing.Elastic.Out)
        .onUpdate(function() {
          updatePath(this.y);
        })
        .onComplete(function() {
          tweeningRef.current = false;
        })
        .start();
    };

    const updatePath = (y) => {
      path.setAttribute("d", `M0,100 Q640,${y} 1280,100`);
    };

    const loop = (time) => {
      if (connectedRef.current) updateCurve();
      TWEEN.update(time);
      requestAnimationFrame(loop);
    };

    const init = () => {
      setSVGTop();
      const cleanupListeners = addListeners();
      loop();

      return () => {
        cleanupListeners();
        if (tweenRef.current) tweenRef.current.stop();
      };
    };

    return init();
  }, []);

  return (
    <motion.div
      ref={ref2}
      variants={variants2}
      initial="hidden"
      animate={Controls2}
      // whileInView="visible"
      viewport={{ once: true }}
      transition={{
        type: "spring",
        duration: 1,
        delay: 0.5,
        ease: "easeInOut",
      }}
      className=""
    >
      <div className="rope-line" ref={devRef}>
        <svg ref={svgRef} id="svg">
          <path
            ref={pathRef}
            id="curve"
            d="M0,100 Q640,100 1280,100"
            fill="none"
            stroke=""
            strokeWidth="1"
          />
        </svg>
      </div>
    </motion.div>
  );
}

export default Rope;
