import React, { useEffect, useRef } from "react";
import exp_1 from "../../assets/images/exp_icon/11 1.png";
import exp_2 from "../../assets/images/exp_icon/11 2.png";
import exp_3 from "../../assets/images/exp_icon/11 3.png";
import {
  motion,
  useInView,
  useAnimation,
} from "framer-motion/dist/framer-motion";
import Rope from "../../components/rope/rope";

const expertiseData = [
  {
    id: 1,
    icon: exp_1,
    title: "Website Development",
    description:
      "Crafting compelling and SEO-optimized websites that conveying your core values, engaging your target audience, and fulfilling your marketing objectives",
    tags: [
      "Front-end development",
      "Back-end development",
      "Full-stack development",
      "Webflow development",
      "Content management system (CMS) integration",
    ],
  },
  {
    id: 2,
    icon: exp_2,
    title: "Mobile App Development",
    description:
      "Developing engaging and intuitive mobile App that enhancing user engagement and experience to drive business growth",
    tags: [
      "Android development",
      "Flutter development",
      "React native development",
      "Integration of APIs and Third-Party Service",
    ],
  },
  {
    id: 3,
    icon: exp_3,
    title: "UI UX Design",
    description:
      "Empowering seamless user experiences  our team Elevates interaction quality for startups and enterprises alike.",
    tags: [
      "Usability testing",
      "Visual design",
      "Heuristic evaluation",
      "User Research",
      "Web accessibility",
      "Redesigning products",
    ],
  },
];

const ExpertiseItem = ({ item }) => {
  const ref = useRef(null);
  const isInView = useInView(ref);
  const controls = useAnimation();
  useEffect(() => {
    if (isInView) {
      controls.start("visible");
    } else {
      controls.start("hidden");
    }
  }, [isInView]);

  const variants = {
    visible: { opacity: 1, scale: 1, rotate: 0 },
    hidden: { opacity: 0, scale: 0, rotate: 14 },
  };

  return (
    <>
      <motion.div
        ref={ref}
        initial="hidden"
        transition={{ duration: 0.8 }}
        animate={controls}
        variants={variants}
        className="expertise_section"
      >
        <div className="row d-flex justify-content-between" key={item.id}>
          <div className="col-lg-4 exp_flex">
            <div className="exp_icon">
              <img src={item.icon} alt="exp" className=""></img>
            </div>
            <div className="exp_title">
              <p>{item.title}</p>
            </div>
          </div>
          <div className="col-lg-7">
            <div className="exp_des">
              <p>{item.description}</p>
            </div>
            <div className="exp_tag_div">
              <div className="exp_tag">
                {item.tags.map((tag, index) => (
                  <div className="tag-pill" key={index}>
                    {tag}
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
       
      </motion.div>
    </>
  );
};

const ExpertiseSection = () => {
  let scrollElementCount = 3;
  const slicedExpertiseData = expertiseData.slice(0, scrollElementCount);
  // console.log(expertiseData);
  return (
    <>
      {slicedExpertiseData.map((item,key) => (
        <>
        <ExpertiseItem key={item.id} item={item}></ExpertiseItem>
        {key != slicedExpertiseData.length-1 && <Rope/>}
        </>
      ))}
    </>
  );
};

export default ExpertiseSection;
